import React, { useState } from "react";
import { graphql } from "gatsby";

import PhoneInputCard from "../components/BetaCard";
import Header from "../components/Header";
import PhoneWalkthrough from "../templates/PhoneWalkthrough";

const IndexPage = ({ data }) => {
  console.log("got graphql data:", data);
  const onPhoneSubmit = (phone) => {
    console.log("Sending a text with the beta link to your phone");
  };
  return (
    <main className="flex flex-col w-full h-full bg-white text-black">
      <Header large={false} />
      <div className="flex flex-col bg-gray-100 h-full items-center">
        <div className="flex flex-col w-full md:pt-10">
          <div className="flex flex-row flex-wrap justify-center items-center sm:flex-nowrap p-5">
            <div className="flex flex-col p-5 md:w-1/2">
              <h1 className="self-center text-4xl font-bold">
                Track your pay, mileage, and expenses, securely and for
                free—while sharing important data with researchers and
                advocates.
              </h1>
              <p className=" text-lg pt-1">
                Developed by Dan Calacci, a PhD student at the MIT Media Lab,
                Gigbox has been designed with workers to make tracking your pay
                and mileage flexible and easy.
              </p>
            </div>
            <div className="flex-initial flex-row max-w-48">
              <PhoneInputCard submitPhone={onPhoneSubmit} />
            </div>
          </div>
          <div className="flex flex-col items-center pt-20">
            <h1 className="font-bold text-4xl">How it Works</h1>
            {data.allMdx.nodes.map((n) => (
              <PhoneWalkthrough
                key={n.id}
                header={n.frontmatter.header}
                content={n.frontmatter.content}
                screenshotUrl={n.frontmatter.screenshot}
              />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export const query = graphql`
  query WalkthroughQuery {
    allMdx(
      filter: { frontmatter: { templateKey: { in: ["walkthroughs"] } } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          header
          content
          screenshot
        }
      }
    }
  }
`;

export default IndexPage;
