import React, { useState } from "react";
import { graphql } from "gatsby";

export default ({ header, content, screenshotUrl }) => {
  return (
    <div className="flex flex-row  justify-around  items-center flex-wrap sm:flex-nowrap p-2 sm:p-5 sm:w-2/3">
      <div className="flex flex-col content-start w-2/3">
        <h1 className="font-bold text-3xl">{header}</h1>
        <p className="pt-1 text-xl">{content}</p>
      </div>
      <div className="carousel rounded-box m-2 border-black border-4 w-1/2 sm:w-1/3">
        <div className="w-full carousel-item">
          <img src={screenshotUrl} className="w-full" />
        </div>
        <div className="w-full carousel-item"></div>
      </div>
    </div>
  );
};
